import React, { useEffect } from "react";

export function Impressum() {
  useEffect(() => {
    document.title = "Impressum";
  });
  return (
    <>
      <div className="kopftrinkspiele">
        <div className="headlinetrinkspiele">
          <h1>Impressum</h1>
        </div>
      </div>
      <br />
      <div className="box">
        <p>
          Linus Stegmann
          <br />
          Linus Stegmann UX Design
          <br />
          Anton Saefkow Platz, 8<br />
          10369 Berlin
        </p>
        <br />

        <h2>Kontakt</h2>
        <br />
        <p>
          Telefon: 015755916945
          <br />
          E-Mail: ich-hab-noch-nie.de@outlook.de
        </p>
        <br />
        <h2>EU-Streitschlichtung</h2>
        <br />
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
        <br />
        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <br />
        <p>
          Wir nehmen an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teil. Zust&auml;ndig ist die
          Universalschlichtungsstelle des Zentrums f&uuml;r Schlichtung e.V.,
          Stra&szlig;burger Stra&szlig;e 8, 77694 Kehl am Rhein (
          <a
            href="https://www.verbraucher-schlichter.de"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.verbraucher-schlichter.de
          </a>
          ).
        </p>
        <br />
        <h2>
          Zentrale Kontaktstelle nach dem Digital Services Act - DSA (Verordnung
          (EU) 2022/265)
        </h2>
        <br />
        <p>
          Unsere zentrale Kontaktstelle f&uuml;r Nutzer und Beh&ouml;rden nach
          Art. 11, 12 DSA erreichen Sie wie folgt:
        </p>
        <p>
          E-Mail: ich-hab-noch-nie.de@outlook.de
          <br />
          Telefon: 015755916945
        </p>
        <p>
          Die für den Kontakt zur Verf&uuml;gung stehenden Sprachen sind:
          Deutsch, Englisch, und.
        </p>
        <br />
      </div>
    </>
  );
}
