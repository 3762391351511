import * as React from "react";

export function Dropdown({ onHome, onImp, onDate, onTrink, onDeep, onDisk }) {
  function handleMenuOne() {
    onHome();
  }
  function handleMenuTwo() {
    onImp();
  }
  function handleMenuThree() {
    onDate();
  }
  function handleMenuFour() {
    onTrink();
  }
  function handleMenuFive() {
    onDeep();
  }
  function handleMenuSix() {
    onDisk();
  }

  return (
    <div className="dropdown boxhead">
      <button onClick={handleMenuOne}>Home</button>
      <button onClick={handleMenuFour}>Trinkspiele</button>
      <button onClick={handleMenuFive}>Deeptalk</button>
      <button onClick={handleMenuSix}>Brainteaser</button>
      <button onClick={handleMenuTwo}>Impressum</button>
      <button onClick={handleMenuThree}>Datenschutzerklärung</button>
    </div>
  );
}
