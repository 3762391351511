import "./App.css";
import "./css/main.css";
import "./css/Spielkarte.css";
import { useState } from "react";
import { MyHeading } from "./Komponenten/MyHeading";
import { AboutPage } from "./Komponenten/AboutPage";
import { Startseite } from "./Seiten/Startseite";
import { NHIE } from "./Seiten/NHIE";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { Footer } from "./Komponenten/Footer";

//funktion um einen Button anzuzeigen der Klicks zählt und anzeigt fragen? Keine!

//mal schauen ob man  eine Heading componente bauen kann, ja natürlch kann man

// Der Kern vong APP Buttons teilen sich den counter um Synchronisierung zu lernen deshalb count hier.

export default function MyApp() {
  return (
    <Router>
      <div className="body">
        <NHIE />
      </div>
    </Router>
  );
}

// was mit Heading geht geht auch mit Content
