import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export function DisskussionLP() {
  return (
    <>
      <HelmetProvider>
        <div>
          <Helmet>
            <title>
              Coole Disskussionsfragen: Top Diskussions Fragen für jeden Anlass
            </title>
            <link
              rel="canonical"
              href="https://ich-hab-noch-nie.de/Diskussionsfragen/"
            />
            <meta
              name="description"
              content="Eine Liste von Diskussionsfragen, die ihr euch zu jeden Anlass stellen könnt, wenn Smalltalk nicht mehr das richtige ist."
            />
          </Helmet>
          <>
            <div className="kopftrinkspiele"></div>
            <div className="box">
              <span className="description">
                <p>Diese Seite befindet sich noch im Aufbau</p>
              </span>
            </div>
          </>
        </div>
      </HelmetProvider>
    </>
  );
}
