import React from "react";
import { Link } from "react-router-dom";

export function NotFound() {
  return (
    <div className="errorcomponent">
      <h1>
        Oh nein... (404) Leider konnten wir die von ihnen gesuchte Seite nicht
        finden.
      </h1>
      <p>Zur Startseite gelangst du hier:</p>
      <Link to="/">Startseite</Link>
    </div>
  );
}
